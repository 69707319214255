// =========
/// Colors
// =========
$color-white: #fff;
$color-blue: #005FA8;
$color-blue-hover: #0a6fbd;
$color-orange: #FF7216;
$color-orange-hover: #ff7e2b;
$color-orange-75: rgba(255, 114, 22, 0.75);
$color-lightgrey: #F2F2F2;
$color-grey: #878787;
$color-grey-hover: #919191;
$color-green: #68cc19;

// ---- INFLUENCER LANDINGPAGE COLORS -----
$text-dark-blue: #142445;
$text-grey: #575E61;
$bg-light-grey: #F2F2F2;



// =========
// fonts > font-family
// =========

// =========
// fonts > font-sizes
// =========
// 1.6rem = 16px (1rem = 10px)
$fontsize-12: 1.2rem;
$fontsize-14: 1.4rem;
$fontsize-default: 1.6rem;
$fontsize-20: 2rem;
$fontsize-21: 2.1rem;
$fontsize-32: 3.2rem;
$fontsize-41: 4.1rem;

// =========
// fonts > font-weight
// =========
$fontweight-400: 400;
$fontweight-500: 500;
$fontweight-700: 700;

// =========
// borders
// =========
$button-border-radius: 4px;
$box-border-radius: 4px;

// =========
// grid and flexcontainer
// =========
$defaultgridgap: 24px;

// =========
// breakpoints
// =========
// breakpoints should be set content based. The list of common breakpoints here should only be used as possible advice if you are not sure where the content related breakpoint to set
// 320px - 480px: Mobile devices
// 481px - 768px: iPads, Tablets
// 769px - 1024px: Small screens, laptops
// 1025px - 1200px: Desktops, large screens
// 1201px and more -  Extra large screens, TV
$breakPoint481: 481px;
$breakPoint620: 620px;
$breakPoint769: 769px;
$breakPoint840: 840px;
$breakPoint1025: 1025px;

