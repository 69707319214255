@mixin font-size($sizeValue) {
  font-size: ($sizeValue) + px;
  font-size: ($sizeValue / 16) + rem;
}

@mixin wrapper {
	width: 100%;
	max-width: 879px;
	margin: 0 auto;
	padding: 0 2rem;
	box-sizing: border-box;
}

@mixin hovertransition {
	transition: 0.2s ease-in-out;
}

@mixin actoutline {
	outline: 4px solid $color-orange;
}

