.maincontent {
	color: $color-blue;
}

.contentsection__outer--blue {
	background: $color-blue;
	color: #fff;
}

.contentsection__outer--grey {
	background: $color-grey;
	color: #fff;
}

.contentsection__outer--light-grey {
	background: $bg-light-grey;
	color: $text-dark-blue;

	h1, h2, h3, h4, h5, h6 {
		color: $color-blue;
	}
}

.contentsection {
	@include wrapper;
	padding-top: 4rem;
	padding-bottom: 4rem;
	&.paddingless {padding-bottom: 0;}
}

.contentsection__padding-small {
	@include wrapper;
	padding-top: 2.5rem;
	padding-bottom: 2.5rem;
	&.paddingless {padding-bottom: 0;}
	.gridcontainer {
		margin-bottom: 0;
	}
}


