.contact-content__container {
	.contact-img-round {
		border-radius: 50%;
		width: 150px;
		margin-bottom: 25px;
	}

	.p-grey {
		color: $text-grey;
	}

	.p-big {
		font-size: 20px;
		line-height: 24px;
	}


}
