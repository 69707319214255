.form__container {
	font-size: 16px;
}

.form__row {
	display: flex;
	flex-direction: row;
	gap: 3rem;
	margin-bottom: 40px;
}

.form__heading {
	margin-top: 50px;
	font-weight: bold;
}

.form__item {
	flex-basis: 50%;

	label {
		display: none;
	}

	input {
		width: 100%;
		border: none;
		padding: 10px;

		box-sizing: border-box;
	}
}

.form__checkboxes--container {
	padding: 20px 0;
}

.form__item--checkbox {
	margin-bottom: 20px;

	label {
		position: relative;
		display: flex;
		flex-direction: row;
	}

	.form__item-checkbox--content {
		margin-left: 20px;

	}

	.checkmark {
		display: block;
		left: 0;
		top: 3px;
		width: 20px;
		height: 20px;
		background: #fff;
		border: 2px solid $color-orange;
		flex-shrink: 0;
	}

	input[type="checkbox"] {
		margin-right: 25px;
		position: absolute;
		z-index: -5;
	}

	input[type="checkbox"]:checked + .checkmark {
		background: $color-orange;
		border: 2px solid $color-orange;
	}
}



