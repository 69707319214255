footer {
	background: $color-lightgrey;
	color: $color-blue;

	.footer__inner {
		@include wrapper;
		padding-top: 3.2rem;
		padding-bottom: 3.2rem;

		img {max-width: 122px;}
	}

	.footer__nav {

		display: flex;
		flex-direction: column;
		align-items: end;


		li {
			padding-bottom: 1.6rem;
			&:last-child {padding: 0;}
		}
	}
}
