.icon-text__container {
	display: flex;
	flex-direction: row;
	gap: 40px;

	margin-bottom: 50px;


	.icon-text__icon {
		width: 93px;
		flex-shrink: 0;
	}

	.icon-text__content {

	}


	color: $text-grey;

	h1, h2, h3, h4, h5, h6 {
		color: $text-dark-blue;
	}
}

@media (max-width: $breakPoint769) {
	.icon-text__container {
		gap: 20px;

		.icon-text__icon {
			width: 75px;
		}
	}
}

@media (max-width: $breakPoint620) {
	.icon-text__container {
		.icon-text__icon {
			width: 60px;
		}
	}

}

@media (max-width: $breakPoint481) {
	.icon-text__container {
		display: flex;
		flex-direction: column;
		gap: 10px;

		.icon-text__icon {
			width: 75px;
		}
	}
}
