.imagemapcontainer  {
	@media (max-width: $breakPoint481) {
		&.gridcontainer {
			margin-bottom: 0;
		}
	}
}

.imagemap__image {
	&__inner {position: relative;}
	img {
		width: 100%;
		display: block;
	}

	@media (max-width: $breakPoint481) {
		margin-bottom: 2rem;
		&.gridcontainer--cols-6 {
			grid-column: span 12;
		}
	}

}


.imagemap__image__circles {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

// image map circles
.imagemap__image__circle {
	@include hovertransition;
	position: absolute;
	font-weight: $fontweight-700;
	background: $color-orange;
	color: #fff;
	opacity: .75;
	cursor: pointer;
	width: 6.5%;
	padding-bottom: 3.25%;
	padding-top: 3.25%;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;

	&::after {
		@include hovertransition;
		content: "";
		position: absolute;
		width: 146%;
		height: 146%;
		border-radius: 50%;
		border: 1px solid $color-orange;
	}

	&.active {
		transform: scale(1.5);
		background-color: $color-blue;
		animation: pulse 500ms;
		&:after {border-color: $color-blue;}
	}

	.imagemap__number {
		position: absolute;
	}
}


@keyframes pulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.8);
	}
	100% {
		transform: scale(1.5);
	}
}

// image map circles position

.imagemap__image__circle--1 {
	right: 12%;
	bottom: 12%;
}

.imagemap__image__circle--2 {
	left: 66%;
	top: 71%;
}

.imagemap__image__circle--3 {
	left: 55%;
	top: 68%;
}

.imagemap__image__circle--4 {
	left: 62%;
	top: 47%;
}

.imagemap__image__circle--5 {
	left: 78%;
	top: 7%;
}

.imagemap__image__circle--6 {
	left: 61%;
	top: 83%;
}

.imagemap__image__circle--7 {
	left: 46%;
	top: 81%;
}

.imagemap__image__circle--8 {
	left: 33%;
	top: 70%;
}

.imagemap__image__circle--9 {
	left: 12%;
	top: 51%;
}

.imagemap__image__circle--10 {
	left: 26%;
	top: 20%;
}

// imagemap description
.imagemap_desc {
	position: relative;
	display: flex;
	align-items: center;

	@media (max-width: $breakPoint481) {
		display: block;
		z-index: 100;
		&.gridcontainer--cols-6 {
			grid-column: span 12;
		}
	}
}

.imagemap_desc__item {
	position: absolute;
	transition: opacity 0.5s ease-in-out;
	opacity: 0;
	background: $color-blue;
	color: #fff;
	border-radius: $box-border-radius;
	text-align: center;
	padding: 3.5em 1.8em;
	height: 210px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media (max-width: $breakPoint769) {
		padding: 1.5em;
	}


	@media (max-width: $breakPoint481) {
		width: 100%;
		box-sizing: border-box;
		top: 0;
	}
}

.imagemap_desc__item.active {
	opacity: 1;
	@media (max-width: $breakPoint481) {
		position: static;
	}
}
