.imagebox {
	background: $color-lightgrey;
	color: #000;

	&--blue {
		background: $color-blue;
		color: #fff;
		display: flex;
		align-items: center;

		header.subheadline h4 {
			background: #fff;
			color: $color-blue;
		}
	}

	&__inner {
		padding: 1.7rem;

	}

	&.imagebox--textright {
		display: grid;
		grid-template-columns: repeat(2, 1fr);

		&.imagebox--textright--smalltext {
			grid-template-columns: 2fr 1fr;

			@media (max-width: $breakPoint620) {
				grid-template-columns: 1fr;
			}
		}

		@media (max-width: $breakPoint620) {
			grid-template-columns: 1fr;
		}

		.video {
			justify-content: center;
		}
	}
}






