// video
.imagebox {
	.video, figure, .video__inner {
		height: 100%;
	}
}

.video {
	display: block;
	video {
		position: absolute;
		z-index: 50;
		width: 100%;
		height: 100%;
		top: 0;
	}
	figure {
		margin: 0;
	}

	&__inner {
		position: relative;
		display: flex;
		align-items: center;
		background: #fff;

		&.act {
			video {z-index: 100;}
			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 50;
			}
		}
	}

	&__controls {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 100;

		&__play {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			cursor: pointer;
			display: block;
			width: 6.4rem;
			height: 6.4rem;
			border-radius: 50%;
			background: url("../Static/Icons/Frontend/arrow-triangle.svg") 1.25em  center no-repeat $color-orange;
			background-size: 2em;
			transition: 0.3s ease-in-out;

			&.act {
				transform: scale(0.4);
				opacity: 0.7;
				left: calc(-100% + 5rem);
				bottom: calc(-100% + 5rem);
				background-image:  url("../Static/Icons/Frontend/pause.svg");
				background-position-x: center;
			}
		}
	}

	.video__poster {
		width: 100%;
		img {
			display: block;
			width: 100%;

		}
	}

	figcaption {
		margin-top: 0.4em;
		font-size: $fontsize-14;
	}

	// fallback image for youtube videos
	&__fallbackimg {
		width: 100%;

		img {width: 100%;}
	}
}

// video opt-in
.video__optin {
	&__link {
		position: relative;
		display: block;
	}

	&__layer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 200;
		background: rgba(0,0,0,0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	&__text {
		font-size: $fontsize-12;
		padding: 2em;
		color: #fff;
		max-width: 440px;

	}

	&__play {
		margin-top: 1em;
	}
}
